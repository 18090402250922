import React from "react";
import {
  Container,
  Box,
  Flex,
  Input,
  Select,
  Text,
  Button,
  Center,
  SimpleGrid,
  useMediaQuery
} from "@chakra-ui/react";

export default function Book() {
  // const [isLargerThan768] = useMediaQuery("(min-width: 1028px)");

  // if (!isLargerThan768) {
  //   return null; // Hide the component if screen width is less than 768px
  // }

  return (
    <Center>
      <Box h="fit-content" p={10} >

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems='center'
          pb={5}
          w="full"
          h='400px'
          boxShadow="dark-lg"
          p="35px"
          bg="white"
          mb={20}
          mt={30}
        >
          <Container maxW="full" display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems='center' className="row">
            <SimpleGrid
              columns={1}
              flexWrap={{ base: "wrap", md: "nowrap" }}
              alignItems="center"
              justifyContent="center"
              boxShadow="dark-lg"
              placeItems='center'
            >
              <Box
                flex={{ base: "1", md: "none" }}
                mb={10}
                mr={{ base: "0", md: "2" }}
              >
                <Text color='black' fontWeight='bold' fontFamily='san-serif' style={{ marginRight: '12px', fontSize: '18px' }} mt={10}>CheckIn</Text>
                <Input
                  type="date"
                  placeholder="Check in"
                  variant="flushed"
                  width='150px'
                  border='1px solid gray'
                  color='#FEA116'
                  p={4}
                  boxShadow='outline'
                />
                <Input
                  type="time"
                  placeholder="Check in"
                  variant="flushed"
                  width='150px'
                  color='#FEA116'
                  border='1px solid gray'
                  p={4}
                  boxShadow='md'
                />
              </Box>
              <Box
                flex={{ base: "1", md: "none" }}
                mb={5}
                mr={{ base: "0", md: "2" }}
                ml={2}
              >
                <Text color='black' fontWeight='bold' fontFamily='sans-serif' style={{ marginRight: '12px', fontSize: '18px' }} mt={10}>Checkout</Text>
                <Input
                  type="date"
                  placeholder="Check in"
                  variant="flushed"
                  color='#FEA116'
                  width='150px'
                  border='1px solid gray'
                  p={4}
                  boxShadow='md'
                />
                <Input
                  type="time"
                  placeholder="Check in"
                  variant="flushed"
                  color='#FEA116'
                  width='150px'
                  border='1px solid gray'
                  p={4}
                  boxShadow='md'
                />
              </Box>
              <Flex justifyContent='center' alignItems='center' flex={1}>
                <Box
                  flex={{ base: "1", md: "none" }}
                  mb={10}

                  mr={{ base: "0", md: "2" }}
                >
                  <Select p={10} size='lg' spacing={4} defaultValue="">
                    <option value="">Adult</option>
                    <option value="1">Adult 1</option>
                    <option value="2">Adult 2</option>
                    <option value="3">Adult 3</option>
                  </Select>
                </Box>
                <Box flex={{ base: "1", md: "none" }} mb={10}>
                  <Select p={10} size="lg" spacing={4} defaultValue="">
                    <option value="">Child</option>
                    <option value="1">Child 1</option>
                    <option value="2">Child 2</option>
                    <option value="3">Child 3</option>
                  </Select>
                </Box>
              </Flex>
              <Box flex={{ base: "1", md: "auto" }} ml={{ base: "0", md: "2" }}>
                <Button
                  color="white"
                  border="none"
                  w="100%"
                  bg="#FEA116"
                  _hover={{ color: "black" }}
                  p={8}
                  width="123px"
                  borderRadius="6px"
                  mr={3}
                >
                  Submit
                </Button>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>

      </Box>
    </Center>
  );
}
